<template >
  <div class="containter">
    <div class="headArea">
      <div class="headContentTitleLine">
        <p class="headContentLineTitleLeft">
          <span class="headContentLineTitle">{{detail.title}}</span>
        </p>
      </div>
      <div class="devideLine"></div>
      <div class="headContentLine">
        <span class="marginLeft15">文章类型：{{detail.typeName}}</span>
      </div>
      <div class="devideLine"></div>
      <div class="headContentLine">
        <span class="marginLeft15">作者：{{detail.author}}</span>
      </div>
      <div class="devideLine"></div>
      <div class="headContentLine">
        <span class="marginLeft15">发布时间：{{detail.publishTime}}</span>
      </div>
    </div>
    <div class="tabMsgArea">
      <!-- <div class="mainTitleLine">
        <span class="marginLeft15">活动风采</span>
      </div>-->
      <div class="rich-text" v-html="detail.content"></div>
    </div>
    <!-- <van-button @click="callTel">测试电话按钮</van-button> -->
  </div>
</template>

<script>
import { Notify } from 'vant';
import { articleInfosDetail } from '../../api/compreInfo';
import * as dd from 'dingtalk-jsapi';

export default {
  name: 'ActivityDetail',

  data() {
    return {
      detail: {}
    };
  },
  watch: {},
  created() {
    dd.biz.navigation.setTitle({
      title: '资讯详情', //控制标题文本，空字符串表示显示默认文本
      onSuccess: function(result) {
        /*结构
        {
        }*/
      },
      onFail: function(err) {}
    });
    this.getRecords();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const this_ = this;
      const { id } = this.$route.query;
      // 利用id查询detail
      articleInfosDetail({
        id: id
      })
        .then(res => {
          if (res.success) {
            const detail = res.data || {};
            this_.detail = detail;
          } else {
            console.log(res.msg);
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    callTel() {
      dd.biz.telephone.showCallMenu({
        phoneNumber: '15858185925', // 期望拨打的电话号码
        code: '+86', // 国家代号，中国是+86
        showDingCall: true, // 是否显示钉钉电话
        onSuccess: function() {
          console.log('success');
        },
        onFail: function() {
          console.log('fail');
        }
      });
    },
    gotoComment() {
      const { id } = this.detail;
      this.$router.push({ name: 'activityComment', query: { id: id } });
    }
  }
};
</script>
<style lang="scss" scoped>
$px: 1rem/37.5;
html body {
  width: 100%;
  height: 100%;
}
.containter {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow-y: auto;
  position: relative;
}
.headArea {
  // height: 203 * $px;
  overflow-y: auto;
  width: 100%;
  background-color: #ffffff;
  margin-bottom: 10 * $px;
}
.devideLine {
  width: 345 * $px;
  height: 1 * $px;
  background-color: #f5f5f5;
  margin-left: 15 * $px;
}
.headContentTitleLine {
  width: 100%;
  line-height: 40 * $px;
  font-size: 14 * $px;
  color: #222222;
  overflow-y: auto;
}
.headContentLine {
  height: 50 * $px;
  width: 100%;
  line-height: 40 * $px;
  font-size: 14 * $px;
  color: #222222;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.headContentLineTitle {
  color: #222222;
}
.headContentLineTitleLeft {
  margin: 0 15 * $px;
}
.marginLeft15 {
  margin-left: 15 * $px;
}
.headImgArea {
  width: 100%;
  height: 160 * $px;
  background-color: grey;
  overflow: hidden;
}
.mainTitleLine {
  width: 100%;
  height: 46 * $px;
  line-height: 46 * $px;
  color: #222222;
  font-size: 14 * $px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.marginLeft10 {
  margin-left: 10 * $px;
}
.mainBottomLine {
  width: 100%;
  height: 44 * $px;
}
.leftMainArea {
  width: 30%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.rightMainArea {
  width: 70%;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.leftMainAreaImg {
  width: 16 * $px;
  height: 14.5 * $px;
  margin-left: 12 * $px;
}
.leftMainAreaFont {
  width: 30 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 5 * $px;
}
.leftMainAreaFont2 {
  width: 62 * $px;
  height: 13 * $px;
  line-height: 13 * $px;
  font-size: 12 * $px;
  color: #555555;
  margin-left: 10 * $px;
}
.leftMainEyeImg {
  width: 16 * $px;
  height: 12 * $px;
}
.leftMainAreaFontNum {
  font-size: 12 * $px;
  color: #555555;
}
.leftMainAreaFontNumNow {
  color: #2797ff;
  font-size: 16 * $px;
}
.msgTab {
  width: 100%;
  height: 130 * $px;
}
.baseLine {
  width: 351 * $px;
  height: 0.5 * $px;
  margin-left: 12 * $px;
  background-color: #eeeeee;
}
.msgTabLine {
  width: 100%;
  height: 20%;
  font-size: 14 * $px;
  color: #555555;
  display: flex;
  align-items: center;
}
.tabMsgArea {
  background-color: #ffffff;
}
.height10 {
  width: 100%;
  height: 10 * $px;
}
.height15 {
  width: 100%;
  height: 15 * $px;
}
.departArea {
  width: 100%;
  height: 60 * $px;
  margin: 10 * $px 0;
  background-color: #ffffff;
}
.avtorArea {
  width: 40 * $px;
  height: 40 * $px;
  border-radius: 20 * $px;
  overflow: hidden;
  background-color: grey;
  margin: 10 * $px;
  float: left;
}
.listItemTitleLineContent {
  width: 280 * $px;
  height: 100%;
  float: left;
}
.listItemTitleLineContent1 {
  font-size: 14 * $px;
  color: #555555;
  margin: 12 * $px 10 * $px 0 * $px 10 * $px;
}
.listItemTitleLineContent2 {
  font-size: 11 * $px;
  color: #999999;
  margin-left: 10 * $px;
}
.rich-text {
  padding: 15 * $px;
  margin-bottom: 10 * $px;
  font-size: 14 * $px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
}
.commentsTitle {
  font-size: 14 * $px;
  color: #222222;
  margin-bottom: 5 * $px;
  margin-left: 12.5 * $px;
}
.commentsItem {
  margin: 15 * $px 12 * $px 0 12 * $px;
  overflow: auto;
  border-bottom: #eeeeee 0.5 * $px solid;
}
.commentsAvtorArea {
  width: 33 * $px;
  float: left;
  display: flex;
  justify-content: center;
}
.commentsAvtor {
  width: 33 * $px;
  height: 33 * $px;
  background-color: grey;
  overflow: hidden;
  border-radius: 16.5 * $px;
}
.commentsContentArea {
  margin-left: 8 * $px;
  width: 310 * $px;
  float: left;
}
.commetUserAndDate {
  height: 15 * $px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.fontName {
  color: #555555;
  font-size: 14 * $px;
}
.fontDate {
  color: #999999;
  font-size: 11 * $px;
}
.fontContent {
  margin: 8 * $px 0 0 0;
  color: #222222;
  font-size: 14 * $px;
}
.commentsBottomLine {
  width: 100%;
  height: 0.5 * $px;
  background-color: #eeeeee;
  margin-top: 15 * $px;
}
.commentsImgLine {
  width: 100%;
  word-break: break-all;
  overflow: auto;
}
.commentsImgItem {
  height: 70 * $px;
  width: 70 * $px;
  margin-top: 9 * $px;
  margin-right: 10 * $px;
  background-color: #dddddd;
  overflow: hidden;
  float: left;
}
.marginBottom60 {
  margin-bottom: 60 * $px;
}
.bottomZanLine {
  position: fixed;
  bottom: 0 * $px;
  left: 0 * $px;
  width: 100%;
  height: 50 * $px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
}
.zanColumn {
  width: 26 * $px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20 * $px;
}
.imgRedHeart {
  width: 22 * $px;
  height: 19 * $px;
  margin-top: 10 * $px;
}
.imgComments {
  width: 19 * $px;
  height: 19 * $px;
  margin-top: 10 * $px;
}
.zanfont {
  margin-top: 2 * $px;
  font-size: 12 * $px;
  color: #555555;
}
.bottomButtonArea {
  width: 230 * $px;
  height: 100%;
  margin-left: 34 * $px;
  display: flex;
  align-items: center;
}
.bottomButton {
  width: 230 * $px;
  height: 33 * $px;
  border-radius: 16.5 * $px;
  text-align: center;
  line-height: 33 * $px;
  font-size: 14 * $px;
}
.baomingButton {
  background-color: #ff192f;
  color: #ffffff;
}
.haveBaomingButton {
  border: #ff192f 1 * $px solid;
  box-sizing: border-box;
  color: #ff192f;
}
.activityEnd {
  background-color: #eeeeee;
  color: #999999;
}
</style>



